<template>
  <div style="margin: -15px">
    <a-spin :spinning="spinning">

    <div class="header">
      <div>名称：</div><a-input v-model:value="text" placeholder="请输入" style="width: 180px" />
      <a-button style="margin-left: 20px" type="primary" @click="search">查询</a-button>
      <div style="margin-left: 120px">
        状态：
        <a-radio-group v-model="statusValue" @change="statusChange">
          <a-radio-button value="已发布">
            已发布
          </a-radio-button>
          <a-radio-button value="未发布">
            未发布
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <div style="border-bottom: 12px solid #F0F2F5">
    </div>
    <div class="box">
      <a-button type="primary" @click="addOpen">+ 新 增</a-button>
      <a-table :pagination="false" style="margin-top: 10px" :columns="columns" :data-source="tableData">
        <template slot="columnNum" slot-scope="text, row, index">
          <a @click="zhuanlanClick(row)">{{text}}</a>
        </template>
        <template slot="operate" slot-scope="text,record, index">
          <a style="margin-right: 5px" @click="editOpen(record)">编辑</a>
          <a-popconfirm
              title="是否确认下架?"
              ok-text="是"
              cancel-text="否"
              @confirm="upDown(record.id)"
          >
            <a v-if="record.isShow === 1" style="margin-right: 5px">下架</a>
          </a-popconfirm>
          <a-popconfirm
              title="是否确认上架?"
              ok-text="是"
              cancel-text="否"
              @confirm="upDown(record.id)"
          >
            <a v-if="record.isShow === 0" style="margin-right: 5px">上架</a>
          </a-popconfirm>
        </template>
      </a-table>
      <div style="margin-top: 30px;padding-left: 20px">
            <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{ pagination.pageNum }} / {{
                Math.ceil(pagination.total / pagination.pageSize)
              }} 页</span>
        <a-pagination
            v-model="pagination.pageNum"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            show-quick-jumper
            show-size-changer
            style="float: right;margin-bottom: 40px"
            @change="pageChange"
            @showSizeChange="pageChange"
        >
        </a-pagination>
      </div>

      <a-drawer
          placement="right"
          :visible="addVisible"
          :width="600"
          title="新增" @close="addClose">
        <a-form-model
            ref="addForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="title" label="期数名称" prop="title">
            <a-input
                placeholder="请输入"
                v-model="form.title"
            />
          </a-form-model-item>
          <a-form-model-item label="背景图" prop="bgImage">
            <div style="display: flex;    flex-direction: column;">
              <a-upload
                  name="file"
                  list-type="picture-card"
                  :show-upload-list="false"
                  class="avatar-uploader"
                  :action="header + '/api/admin/common/update'"
                  @change="handleChange($event, 'form', 'bgImage')"
              >
                <img style="width: 120px;height: 160px" v-if="form.bgImage" :src="form.bgImage" alt="" />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">
                    上传
                  </div>
                </div>
              </a-upload>
              <span style="font-size: 15px;">注：图片大小不超过2MB</span>
            </div>
          </a-form-model-item>
          <a-form-model-item label="banner" prop="banner">
            <div style="display: flex;    flex-direction: column;">
              <a-upload
                  name="file"
                  list-type="picture-card"
                  :show-upload-list="false"
                  class="avatar-uploader-banner"
                  :action="header + '/api/admin/common/update'"
                  @change="handleChange($event, 'form', 'banner')"
              >
                <img style="width: 250px;height: 90px" v-if="form.banner" :src="form.banner" alt="avatar" />
                <div v-else>
                  <a-icon :type="bannerLoading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">
                    上传
                  </div>
                </div>
              </a-upload>
              <span style="font-size: 15px;">注：尺寸1920x520，图片大小不超过2MB</span>
            </div>
          </a-form-model-item>

          <a-form-model-item label="作品标签颜色" prop="labelImage">
            <div style="display: flex;justify-content: space-around;border: 1px solid #D9D9D9;width: 180px;border-radius: 5px">
              <div v-for="item in labelColor" style="margin: 10px;cursor: pointer" @click="labelColorChange('form',item)" :style="{border: form.labelImage === item ? '1px solid #1890FF' : '1px solid white'}">
                <img :src="item" style="width: 50px;height: 50px;margin: 3px">
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="addClose">
            取消
          </a-button>
          <a-button type="primary" @click="addOk">
            确定
          </a-button>
        </div>
      </a-drawer>

      <a-drawer           placement="right"
                          :visible="editVisible"
                          :width="600" title="编辑" @close="editClose">
        <a-form-model
            ref="editForm"
            :model="editForm"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="name" label="期数名称" prop="title">
            <a-input
                placeholder="请输入"
                v-model="editForm.title"
            />
          </a-form-model-item>
          <a-form-model-item label="背景图" prop="bgImage">
            <div style="display: flex;    flex-direction: column;">
              <a-upload
                  name="file"
                  list-type="picture-card"
                  :show-upload-list="false"
                  class="avatar-uploader"
                  :action="header + '/api/admin/common/update'"
                  @change="handleChange($event, 'editForm', 'bgImage')"
              >
                <img style="width: 120px;height: 160px" v-if="editForm.bgImage" :src="editForm.bgImage" alt="avatar" />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">
                    上传
                  </div>
                </div>
              </a-upload>
              <span style="font-size: 15px;">注：图片大小不超过2MB</span>
            </div>
          </a-form-model-item>
          <a-form-model-item label="banner" prop="banner">
            <div style="display: flex;    flex-direction: column;">
              <a-upload
                  name="file"
                  list-type="picture-card"
                  :show-upload-list="false"
                  class="avatar-uploader-banner"
                  :action="header + '/api/admin/common/update'"
                  @change="handleChange($event, 'editForm', 'banner')"
              >
                <img style="width: 250px;height: 90px" v-if="editForm.banner" :src="editForm.banner" alt="avatar" />
                <div v-else>
                  <a-icon :type="bannerLoading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">
                    上传
                  </div>
                </div>
              </a-upload>
              <span style="font-size: 15px;">注：尺寸1920x520，图片大小不超过2MB</span>
            </div>
          </a-form-model-item>

          <a-form-model-item label="作品标签颜色" prop="labelImage">
            <div style="display: flex;justify-content: space-around;border: 1px solid #D9D9D9;width: 180px;border-radius: 5px">
              <div v-for="item in labelColor" style="margin: 10px;cursor: pointer" @click="labelColorChange('editForm',item)" :style="{border: editForm.labelImage === item ? '1px solid #1890FF' : '1px solid white'}">
                <img :src="item" style="width: 50px;height: 50px;margin: 3px">
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button :style="{ marginRight: '8px' }" @click="editClose">
            取消
          </a-button>
          <a-button type="primary" @click="editOk">
            确定
          </a-button>
        </div>
      </a-drawer>

      <a-drawer           placement="right"
                          :visible="zjzlVisible"
                          :width="1200" title="设置" @close="zjzlVisibleClose">
        <div style="font-size: 18px">查询人员</div>
        <div style="display: flex;justify-content: space-between;width: 800px;padding: 15px">
          <div >
            专家姓名：<a-input style="width: 250px" placeholder="请输入" v-model="zjName"/>
          </div>
          <div>
            医院：<a-input style="width: 250px" placeholder="请输入" v-model="zjYiYuan"/>
          <a-button type="primary" @click="zjChaXun" style="margin-left: 30px">查询</a-button>
          </div>
        </div>
        <a-table :loading="zjLoading" :pagination="false" style="margin-top: 10px;margin-bottom: 20px" :columns="zjColumns" :data-source="zjTableData">
          <template slot-scope="text, row, index" slot="headimg">
            <img :src="text" style="width: 70px;height: 70px">
          </template>
          <template slot-scope="text, row, index" slot="operate">
            <a style="color: red" v-if="zlIdList.some(item => item === row.id)">已添加</a>
            <a-popconfirm
                title="是否确认添加?"
                ok-text="是"
                cancel-text="否"
                @confirm="zjAdd(row.id)"
                v-else
            >
              <a>添加</a>
            </a-popconfirm>
          </template>
        </a-table>
        <div style="font-size: 18px;">专家专栏列表</div>
        <a-table :loading="zlLoading" :pagination="false" style="margin-top: 15px;margin-bottom: 20px" :columns="zlColumns" :data-source="zlTableData">
          <template slot-scope="text, row, index" slot="headimg">
            <img :src="text" style="width: 70px;height: 70px">
          </template>
          <template slot-scope="text, row, index" slot="operate">
            <a @click="upOrDown(row.id, 'up')" style="margin-right: 5px" v-if="index !== 0">上移</a>
            <a @click="upOrDown(row.id, 'down')" v-if="zlTableData.length -1 !== index" style="margin-right: 5px" >下移</a>
            <a-popconfirm
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="zlRemove(row.id)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </a-table>
<!--        <div style="margin-top: 30px;padding-left: 20px">-->
<!--            <span style="font-size: 17px;color: #929292">共 {{ zlPagination.total }} 条记录 第 {{ zlPagination.pageNum }} / {{-->
<!--                Math.ceil(zlPagination.total / zlPagination.pageSize)-->
<!--              }} 页</span>-->
<!--          <a-pagination-->
<!--              v-model="zlPagination.pageNum"-->
<!--              :page-size="zlPagination.pageSize"-->
<!--              :total="zlPagination.total"-->
<!--              show-quick-jumper-->
<!--              show-size-changer-->
<!--              style="float: right;margin-bottom: 40px"-->
<!--              @change="zlPageChange"-->
<!--              @showSizeChange="zlPageChange"-->
<!--          >-->
<!--          </a-pagination>-->
<!--        </div>-->
      </a-drawer>
    </div>

    </a-spin>
  </div>
</template>

<script>
import {update} from "@/utils/update";
import api_header_config from "@/service/api_header_config";
import {
  delYunColumnZLRemove,
  getYunColumnApi,
  getYunColumnUpDownApi,
  getYunColumnZLApi, getYunColumnZLPeopleApi,
  postYunColumnAddApi, postYunColumnZLAddApi,
  putYunColumnEditApi, putYunColumnZLUpDown
} from "@/service/systemApi";

export default {
  name: "PersonalHistory",
  data() {
    return {
      zlTableData: [],
      zlLoading: false,
      zlColumns: [
        {
          title: '头像',
          dataIndex: 'headimg',
          scopedSlots: { customRender: 'headimg' },
        },
        {
          title: '专家姓名',
          dataIndex: 'name'
        },
        {
          title: '医院',
          dataIndex: 'hospital'
        },
        {
          title: '科室',
          dataIndex: 'departmentCode'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
        }
      ],
      zjLoading: false,
      zjTableData: [],
      zjColumns: [
        {
          title: '头像',
          dataIndex: 'headimg',
          scopedSlots: { customRender: 'headimg' },
        },
        {
          title: '专家姓名',
          dataIndex: 'name'
        },
        {
          title: '医院',
          dataIndex: 'hospital'
        },
        {
          title: '科室',
          dataIndex: 'departmentCode'
        },
        {
          title: '操作',
          width: 100,
          scopedSlots: { customRender: 'operate' },
        }
      ],
      zjName: '',
      zjYiYuan: '',
      zjzlVisible: false,
      labelColor: [
          'https://cos.yunicu.com/images/bcba5c45-c87f-4a31-a734-303866f44083.png',
          'https://cos.yunicu.com/images/3a971227-2013-4756-bf51-e6022a4b843c.png'
      ],
      loading: false,
      header: api_header_config.api_header,
      // header: 'http://admin-dev-api.yunicu.com',
      editVisible: false,
      labelCol: { span: 6},
      wrapperCol: { span: 16 },
      rules: {
        title: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        labelImage: [{ required: true, message: "请选择", trigger: 'blur' }],
        banner: [{ required: true, message: "请上传", trigger: 'blur' }],
        bgImage: [{ required: true, message: '请上传', trigger: 'blur' }],
      },
      form: {
        banner: null,
        bgImage: 'https://cos.yunicu.com/images/e3c06f21-4eb1-40e1-99a3-d4d2205d17e3.png',
        labelImage: 'https://cos.yunicu.com/images/bcba5c45-c87f-4a31-a734-303866f44083.png',
      },
      editForm: {},
      addVisible: false,
      pagination: {   //存储大table的分页
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      zlPagination: {   //存储大table的分页
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      columns: [
        {
          title: '名称',
          dataIndex: 'title'
        },
        {
          title: '专栏专家',
          align: 'center',
          dataIndex: 'columnNum',
          scopedSlots: { customRender: 'columnNum' },
        },
        {
          title: '创建人',
          dataIndex: 'createdName'
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime'
        },
        {
          title: '操作',
          width: 180,
          scopedSlots: { customRender: 'operate' },
        }
      ],
      tableData: [],
      spinning: false,
      text: '',
      statusValue: '已发布',
      zjStatus: true,
      cycleId: null,
      zlIdList: [],
      bannerLoading:false,
    }//return
  },
  created() {
    this.getTableData()
  },
  methods: {
    async upOrDown (id, type) {
      this.zlLoading = true
      const data = {
        id,
        move: type,
        cycleId: this.cycleId
      }
      const res = await putYunColumnZLUpDown(data)
      if (res.code === 0) {
        this.$message.success('操作成功')
        await this.getZlTableData(this.cycleId)
      } else {
        this.$message.warning(res.message)
      }
      this.zlLoading = false
    },
    async getZlTableData (cycleId) {
      this.zlLoading = true
      const data = {
        cycleId
      }
      const res = await getYunColumnZLApi(data)
      if (res.code === 0) {
        this.zlLoading = false
        this.zlTableData = res.data
        if (res.data && res.data.length > 0) {
          this.zlIdList = res.data.map(item => {
            return item.expertId
          })
        }
      }
    },
    async upDown (id) {
      this.spinning = true
      const data = {
        id
      }
      const res = await getYunColumnUpDownApi(data)
      if (res.code === 0) {
        this.$message.success('操作成功')
        await this.getTableData()
      } else {
        this.$message.warning(res.message)
      }
      this.spinning = false
    },
    async getTableData () {
      this.spinning = true
      const data = {
        title: this.text,
        isShow: this.statusValue === '已发布' ? 1 : 0,
        pageNo: this.pagination.pageNum,
        pageSum: this.pagination.pageSize,
      }
      const res = await getYunColumnApi(data)
      if (res.code === 0) {
        this.tableData = res.data
        this.pagination.total = res.count
       this.spinning = false
      }
    },
    async zlRemove (id) {
      const data = {
        id
      }
      const res = await delYunColumnZLRemove(data)
      if (res.code === 0) {
        this.$message.success('删除成功')
        await this.getZlTableData(this.cycleId)
        await this.getTableData()
        const index = this.zlIdList.findIndex(item => item === id)
        this.zlIdList.splice(index, 1)
      } else {
        this.$message.warning(res.message)
      }
    },
    async zjAdd (id) {
      const data = {
        cycleId: this.cycleId,
        expertId: id
      }
      const res = await postYunColumnZLAddApi(data)
      if (res.code === 0) {
        this.$message.success('添加成功')
        await this.getZlTableData(this.cycleId)
        await this.getTableData()
      } else {
        this.$message.warning(res.message)
      }
    },
    zjChaXun () {
      this.getZJTableData()
    },
    zjzlVisibleClose () {
      this.zjzlVisible = false
    },
    editClose () {
      this.editVisible = false
    },
    addClose () {
      this.addVisible = false
    },
    zhuanlanClick (row) {
      this.zlPagination.pageNum = 1
      this.zlPagination.pageSize = 10
      this.zjTableData = []
      this.zlIdList = []
      this.zjName = ''
      this.zjYiYuan = ''
      this.zjzlVisible = true
      this.cycleId = row.id
      this.getZlTableData(row.id)
      // this.getZJTableData()
    },
    async getZJTableData () {
      this.zjName = this.zjName.trim()
      this.zjYiYuan = this.zjYiYuan.trim()
      if (!this.zjName && this.zjName.trim().length === 0 && !this.zjYiYuan && this.zjYiYuan.trim().length === 0) {
        this.zjTableData = []
        this.$message.info('请输入专家姓名或医院')
       return
      }
      this.zjStatus = false
      this.zjLoading = true
      const data = {
        name: this.zjName,
        hospital: this.zjYiYuan
      }
      const res = await getYunColumnZLPeopleApi(data)
      if (res.code === 0) {
        this.zjTableData = res.data
        this.zjLoading = false
      }
    },
    labelColorChange (type,item) {
      if (item === this[type].labelImage) {
        // this[type].labelImage = null
        return
      }
      this[type].labelImage = item
    },
    handleChange (e, aForm, type) {
      if (e.file.status !== 'done') {
        if (type !== 'banner') this.loading = true
        else this.bannerLoading = true
        return
      }
      if (e.file.size > 512000 * 4) {
        if (type !== 'banner') this.loading = false
        else this.bannerLoading = false
        this.$message.warning('请上传小于2MB的图片')
        return;
      }
      if (e.file.response.code === 0) {
        this[aForm][type] = e.file.response.data.url
        if (type !== 'banner') this.loading = false
        else this.bannerLoading = false
        this.$message.success('上传成功')
      }
    },
    search () {
      this.pagination.pageNum = 1
      this.getTableData()
    },
    statusChange (e) {
      this.pagination.pageNum = 1
      this.statusValue = e.target.value
      this.getTableData()
      // if (e.target.value === '未发布') {
      //
      // } else if (e.target.value === '已发布') {
      //
      // }
    },
    editOpen (row) {
      this.editForm = {...row}
      this.editVisible = true
    },
    editOk () {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const data = {
            ...this.editForm
          }
          const res = await putYunColumnEditApi(data)
          if (res.code === 0) {
            this.$message.success('编辑成功')
            // this.$refs.editForm.resetFields()
            this.editVisible = false
            await this.getTableData()
          } else {
            this.$message.warning(res.message)
          }
        }
      })
    },
    addOpen () {
      this.addVisible = true
    },
    async addOk () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const data = {
            title: this.form.title,
            banner: this.form.banner,
            bgImage: this.form.bgImage,
            labelImage: this.form.labelImage
          }
          const res = await postYunColumnAddApi(data)
          if (res.code === 0) {
            this.$message.success('新增成功')
            this.addVisible = false
            this.$refs.addForm.resetFields()
            await this.getTableData()
          } else {
            this.$message.warning(res.message)
          }
        }
      })
    },
    async delConfirm (id) {

    },
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no
      this.pagination.pageSize = page_size
      this.getTableData()
    },
    zlPageChange(page_no, page_size) {
      this.pagination.pageNum = page_no
      this.pagination.pageSize = page_size
    },
  }
}
</script>

<style>
.header {
  padding: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  /*border-bottom: 10px solid #F0F2F5;*/
}
.box {
  padding: 10px 20px 20px 20px;
}
.avatar-uploader > .ant-upload {
  width: 120px;
  height: 160px;
}.avatar-uploader-banner > .ant-upload {
  width: 250px;
  height: 90px;
}
</style>